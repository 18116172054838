import {Component, inject, OnInit} from '@angular/core';
import { fromEvent, Observable } from "rxjs";
import { environment } from "../environments/environment";
import packageInfo from "../../package.json";
import { TranslateService } from '@ngx-translate/core';
import { BasicsRepository } from './data/repository/basics.repository';
import { Messaging } from '@angular/fire/messaging';
import {NotificationService} from "./share/services/notification.service";
import {UrlUtil} from "./share/utils/url.util";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  title = 'panel';
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  status: string = 'online';
  devMode: boolean = true;
  version = packageInfo.version;

  constructor(private translate: TranslateService,
              private notificationService:NotificationService,
              private urlUtil:UrlUtil,
              private baseRepo: BasicsRepository) {
    localStorage.setItem('domain', this.urlUtil.getDomain());

    let lang = baseRepo.getLanguage();
    if (lang == null)
      lang = 'en';

    baseRepo.switchLang(lang);

  }

  ngOnInit(): void {
    this.devMode = environment.production;
    this.handleAppConnectivityChanges();
  }


  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe(event => {
      this.status = 'online';
    });

    this.offlineEvent.subscribe(event => {
      this.status = 'Offline';
    });
  }
}
