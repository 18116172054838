{
  "name": "panel",
  "version": "0.1.200",
  "scripts": {
    "ng": "ng",
    "start": "ng serve  --host 0.0.0.0 --disable-host-check",
    "build": "ng build  && npm run sentry:sourcemaps",
    "build:dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "docker:build": "docker build . -t atwork.registry.jetbrains.space/p/atw/containers/$npm_package_name:$npm_package_version",
    "docker:push": "docker push atwork.registry.jetbrains.space/p/atw/containers/$npm_package_name:$npm_package_version",
    "docker:tag:latest": "docker tag atwork.registry.jetbrains.space/p/atw/containers/$npm_package_name:$npm_package_version atwork.registry.jetbrains.space/p/atw/containers/$npm_package_name:latest",
    "docker:push:latest": "docker push atwork.registry.jetbrains.space/p/atw/containers/$npm_package_name:latest",
    "docker:publish": "npm run docker:build && npm run docker:tag:latest && npm run docker:push && npm run docker:push:latest",
    "test": "ng test",
    "pretty-quick": "pretty-quick",
    "release": "standard-version",
    "release:major": "standard-version --release-as major",
    "release:minor": "standard-version --release-as minor",
    "release:patch": "standard-version --release-as patch",
    "serve": "npm run build:dev && http-server -a localhost -p 4200 -c-1 dist/",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org atwork-yf --project javascript-angular ./dist && sentry-cli sourcemaps upload --org atwork-yf --project javascript-angular ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.5",
    "@angular/cdk": "^18.0.5",
    "@angular/common": "^18.0.5",
    "@angular/compiler": "^18.0.5",
    "@angular/core": "^18.0.5",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.0.5",
    "@angular/material": "^18.0.5",
    "@angular/material-moment-adapter": "^18.0.5",
    "@angular/platform-browser": "^18.0.5",
    "@angular/platform-browser-dynamic": "^18.0.5",
    "@angular/router": "^18.0.5",
    "@angular/service-worker": "^18.0.5",
    "@bluehalo/ngx-leaflet": "^18.0.2",
    "@bluehalo/ngx-leaflet-draw": "^18.0.1",
    "@editorjs/checklist": "^1.6.0",
    "@editorjs/editorjs": "^2.29.1",
    "@editorjs/header": "^2.8.1",
    "@editorjs/inline-code": "^1.5.0",
    "@editorjs/marker": "^1.4.0",
    "@editorjs/nested-list": "^1.4.2",
    "@editorjs/quote": "^2.6.0",
    "@editorjs/table": "^2.3.0",
    "@editorjs/text-variant-tune": "^1.0.2",
    "@editorjs/underline": "^1.1.0",
    "@floating-ui/dom": "^1.6.7",
    "@fullcalendar/angular": "^6.1.10",
    "@fullcalendar/core": "^6.1.11",
    "@fullcalendar/daygrid": "^6.1.11",
    "@fullcalendar/interaction": "^6.1.11",
    "@fullcalendar/list": "^6.1.4",
    "@fullcalendar/moment": "^6.1.15",
    "@fullcalendar/resource": "^6.1.15",
    "@fullcalendar/resource-timeline": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.4",
    "@katoid/angular-grid-layout": "^2.2.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ng-select/ng-select": "^13.3.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.10.2",
    "@sentry/angular": "^8.13.0",
    "@sentry/cli": "^2.32.1",
    "@sweetalert2/ngx-sweetalert2": "^12.1.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "@worktile/gantt": "^17.0.0-next.0",
    "angular-expressions": "^1.2.1",
    "angular-gridster2": "^18.0.1",
    "apexcharts": "^3.49.1",
    "bootstrap": "^5.2.0",
    "bootstrap-icons": "^1.11.3",
    "country-state-city": "^3.1.4",
    "d3": "^7.9.0",
    "date-fns": "^3.6.0",
    "dayjs": "^1.11.7",
    "docxtemplater": "^3.50.0",
    "echarts": "^5.5.1",
    "editorjs-alert": "^1.1.4",
    "editorjs-indent-tune": "^1.3.0",
    "editorjs-text-color-plugin": "^2.0.4",
    "firebase": "^10.12.2",
    "gauge-chart": "^1.0.0",
    "google-libphonenumber": "^3.2.25",
    "grapesjs": "^0.21.12",
    "grapesjs-blocks-basic": "^1.0.2",
    "grapesjs-preset-webpage": "^1.0.3",
    "html-to-image": "^1.11.11",
    "html2canvas": "^1.4.1",
    "intl-tel-input": "^17.0.13",
    "jalali-moment": "^3.3.11",
    "jalali-ts": "^2.0.4",
    "jexl": "^2.3.0",
    "jquery": "^3.6.1",
    "js-tiktoken": "^1.0.12",
    "jwt-decode": "^3.1.2",
    "leaflet": "^1.9.3",
    "leaflet-draw": "^1.0.4",
    "lottie-web": "^5.12.2",
    "moment-timezone": "^0.5.43",
    "ng-apexcharts": "^1.11.0",
    "ng-event-bus": "^7.0.1",
    "ng-persian-datepicker": "^8.0.1",
    "ngx-dropzone": "^3.1.0",
    "ngx-echarts": "^18.0.0",
    "ngx-grapesjs": "^18.0.0",
    "ngx-lightbox": "^3.0.0",
    "ngx-lottie": "^11.0.2",
    "ngx-mask": "^17.0.8",
    "ngx-toastr": "^19.0.0",
    "papaparse": "^5.4.1",
    "pizzip": "^3.1.7",
    "rxjs": "~7.8.1",
    "select2": "^4.1.0-rc.0",
    "short-unique-id": "^5.2.0",
    "sweetalert2": "^11.11.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular/cli": "~18.0.6",
    "@angular/compiler-cli": "^18.0.5",
    "@angular/localize": "^18.0.5",
    "@types/d3": "^7.4.3",
    "@types/jasmine": "~4.0.0",
    "@types/jexl": "^2.3.4",
    "@types/leaflet": "^1.9.3",
    "@types/leaflet-draw": "^1.0.6",
    "@types/select2": "^4.0.55",
    "codex-notifier": "^1.1.2",
    "codex-tooltip": "^1.0.5",
    "editorjs-drag-drop": "^1.1.14",
    "editorjs-toggle-block": "^0.3.16",
    "jasmine-core": "~4.3.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "prettier": "^3.3.2",
    "pretty-quick": "^4.0.0",
    "standard-version": "^9.5.0",
    "typescript": "~5.4.5"
  }
}
