import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationComponent} from './layout/authentication/authentication.component';
import {PanelComponent} from './layout/panel/panel.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PanelInterceptor} from './share/services/interceptor/panel-interceptor.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ShareModule} from './share/share.module';
import {NgEventBus} from 'ng-event-bus';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {ImportFilesComponent} from './import-files/import-files.component';
import {
  PreviewOfImportFileComponent
} from './import-files/preview-of-import-file/preview-of-import-file.component';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    PanelComponent,
    ImportFilesComponent,
    PreviewOfImportFileComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-bottom-left',
      progressBar: true,
      preventDuplicates:true
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
      extend: true,
    }),
    ShareModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   // enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PanelInterceptor,
      multi: true,
    },
    NgEventBus,
    provideLottieOptions({
      player: () => player,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return player;
}
